<template>
  <div class="fill-height py-3 py-md-10">
    <v-container>
      <div v-html="terms_of_service"></div>
    </v-container>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TermsOfService",
  computed: {
    ...mapState({
      terms_of_service: state => state.Organization.terms_of_service
    })
  },
  created() {
    this.$store.dispatch('Organization/onGetTermsOfService');
  }
}
</script>

<style scoped>

</style>
